@import "variables";
@import "foundation-sites/scss/foundation";

.title-bar {
  padding: 1.5rem 0.5rem;
  background-color: $title-bar-bg;
  color: $title-bar-color;

  .topbar {
    div {
      z-index: 100;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
  }

  &::before {
    right: 0;
    top: 0;
    border: solid $uclg-red;
    border-width: 30px 30px 0 0;
  }

  &::after {
    top: 0;
    left: 0;
    border: solid $uclg-green;
    border-width: 30px 0 0 30px;
  }

  @media only screen and (max-width: 640px) {
    &::before,
    &::after {
      width: 50px;
      height: 50px;
    }

    &::before {
      border-width: 15px 15px 0 0;
    }

    &::after {
      border-width: 15px 0 0 15px;
    }
  }
}

.navbar {
  font-family: 'OpenSans Bold', sans-serif;
  background-color: $navbar-color;

  .main-nav__link {
    a {
      color: $navbar-fg;
    }

    &--active a {
      color: $navbar-fg-hover;
    }
  }
}

.topbar_{
  &_search{
    input,
    input:focus{
      background-color: $navbar-color;
      color: $navbar-fg-hover;
      border: 1px solid;
    }

    input::placeholder,
    textarea::placeholder{
      color: $navbar-fg-hover;
    }

    svg{
      color: $navbar-fg-hover;
    }
  }
}

.topbar__dropmenu > ul > li > a {
  color: $navbar-fg !important;

  &::after {
    border-top-color: $uclg-orange !important;
  }
}
