// Define custom properties as css color variables

$uclg-green: #43ea00;
$uclg-green-rgb: 67,234,0;
$uclg-red: #ff0000;
$uclg-red-rgb: 255,0,0;
$uclg-grey: #575756;
$uclg-grey-rgb: 87,87,86;
$uclg-orange: #EC6221;
$uclg-orange-rgb: 236,98,33;

$body-font-color: #494949;
$body-background-color: #fafafa;
$navbar-color: $uclg-grey;
$navbar-rgb: 35,31,32;
$title-bar-color: #ffffff;
$title-bar-rgb: 0,0,0;
// Variables

$primary: $uclg-red;
$primary-rgb: $uclg-red-rgb;
$secondary: $uclg-green;
$secondary-rgb: $uclg-green-rgb;
$success: #6eeb83;
$success-rgb:110,235,131;
$warning: #f46036;
$warning-rgb: 244,96,54;
$alert: #EE3138;
$alert-rgb: 239,45,86;

$link-color: #ffffff;
$title-bar-bg: $uclg-grey;
$navbar-fg: #ffffff;
$navbar-fg-hover: #ffffff;
$navbar-bg: $uclg-grey;

$footer-main-bg: #575756;
$footer-mini-bg: #575756;

$proposals: #238ff7;
$actions: #6eeb83;
$debates: #fa6c96;
$meetings: #fabc6c;

$twitter: #55acee;
$facebook: #3b5998;
$google: #dd4b39;

// background colors used in public diff views
$color-addition: #e6ffed;
$color-removal: #ffeef0;

:root{
  --primary: #{$primary};
  --primary-rgb: #{$primary-rgb};
  --secondary: #{$secondary};
  --secondary-rgb: #{$secondary-rgb};
  --success: #{$success};
  --success-rgb: #{$success-rgb};
  --warning: #{$warning};
  --warning-rgb: #{$warning-rgb};
  --alert: #{$alert};
  --alert-rgb: #{$alert-rgb};
  --proposals: #{$proposals};
  --actions: #{$actions};
  --debates: #{$debates};
  --meetings: #{$meetings};
  --twitter: #{$twitter};
  --facebook: #{$facebook};
  --google: #{$google};
}
