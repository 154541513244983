.program-background.a {
  background-size: 50%;
  background-position: top right;
  background-repeat: no-repeat;

  @media only screen and (max-width: 600px) {
    background-size: 75%;
  }
}

.program-background.b {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right 55px;
}

.conference-programme,
.conference-information {
  font-size: 115%;

  .programme-extra,
  a,
  p {
    font-size: 115%;
  }
}
