@font-face {
  font-family: 'OfficinaSans Book';
  src:
    url('fonts/uclg/itc-officina-sans-std-book.woff2') format('woff2'),
    url('fonts/uclg/itc-officina-sans-std-book.woff') format('woff'),
    url('fonts/uclg/itc-officina-sans-std-book.ttf') format('truetype');
}

@font-face {
  font-family: 'OfficinaSans Bold';
  src:
    url('fonts/uclg/itc-officina-sans-std-bold.woff2') format('woff2'),
    url('fonts/uclg/itc-officina-sans-std-bold.woff') format('woff'),
    url('fonts/uclg/itc-officina-sans-std-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'OfficinaSans Book', sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6{
  font-family: 'OfficinaSans Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  // text-rendering: optimizeLegibility;
  // letter-spacing: -.5px;
}
