@import "variables";

.main-footer {
  background: $footer-main-bg;

  a {
    color: $link-color;
  }
  .main-footer__badge {
    position: unset;
  }
}

.mini-footer{
  background: $footer-mini-bg;

  a {
    color: $link-color;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
  }

  &::before {
    right: 0;
    bottom: 0;
    border: solid $uclg-red;
    border-width: 0 30px 30px 0;
  }

  &::after {
    bottom: 0;
    left: 0;
    border: solid $uclg-green;
    border-width: 0 0 30px 30px;
  }

  @media only screen and (max-width: 640px) {
    &::before,
    &::after {
      width: 50px;
      height: 50px;
    }

    &::before {
      border-width: 0 15px 15px 0;
    }

    &::after {
      border-width: 0 0 15px 15px;
    }
  }
}
